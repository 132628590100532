<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2 ml-2">Delivery</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="secondary" class="mr-1" @click.stop="update()">
					<v-icon left light small>mdi-reload</v-icon>
					Actualizar
				</v-btn>
				<v-btn color="accent" class="mr-2" @click.prevent="showRegisterDialog = true">
					<v-icon left light small>mdi-alarm</v-icon>
					Nuevo
				</v-btn>
			</div>
		</div>
		<!-- <div class="emb-card pa-3"> -->
			<emb-data-table-without-filter :resource="resource">
				<tr slot="heading">
					<th>CANAL</th>
					<th>N°</th>
					<th>FECHA Y HORA</th>
					<th>CLIENTE</th>
					<!-- <th>Canal delivery</th> -->
					<th class="text-center">ESTADO</th>
					<th>DIRECCIÓN DE ENTREGA</th>
					<th class="text-right">TOTAL</th>
					<th class="text-right">ACCIONES</th>
				</tr>
				<tr slot-scope="{ index, row }" :key="index">
					<td>
						<v-icon v-if="row.channel_id == '01'" large color="info"
							>mdi-album</v-icon
						>
						<v-icon v-if="row.channel_id == '02'" large color="success"
							>mdi-whatsapp</v-icon
						>
						<v-icon v-if="row.channel_id == '04'" large color="accent"
							>mdi-phone</v-icon
						>
					</td>
					<td>{{ index }}</td>
					<td>{{ row.date_of_issue + ' ' + row.time_of_issue }}</td>
					<td>{{ row.customer }}</td>
					<!-- <td>
						<v-icon v-if="row.channel_id == '01'" left color="warning" small
							>mdi-wifi</v-icon
						>
						<v-icon v-if="row.channel_id == '04'" left color="warning" small
							>mdi-phone</v-icon
						>
						{{ row.channel_description }}
					</td> -->
					<td class="text-center">
						<v-chip color="red" text-color="white" x-small v-if="row.state == '01'">
							{{ row.state_description }}
						</v-chip>
						<v-chip color="success" text-color="white" x-small v-if="row.state == '02'">
							{{ row.state_description }}
						</v-chip>
						<v-chip color="info" text-color="white" x-small v-if="row.state == '04'">
							{{ row.state_description }}
						</v-chip>
					</td>
					<td>{{ row.shipping.location.address }}</td>
					<td class="text-right">{{ row.currency_type }} {{ row.total }}</td>
					<td class="text-right">
						<template v-if="row.state == '01'">
							<v-tooltip top>
								<template
									v-slot:activator="{ on }"
									v-if="row.type_sale == 'in_store'"
								>
									<v-btn
										color="primary"
										class="ma-1"
										fab
										x-small
										dark
										@click.prevent="seeLocation(row.shipping.location)"
										v-on="on"
									>
										<v-icon>mdi-map-marker</v-icon>
									</v-btn>
								</template>
								<span>Ver ubicación</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn
										color="success"
										class="ma-1"
										fab
										x-small
										dark
										@click.prevent="confirmed(row.id)"
										v-on="on"
									>
										<v-icon>mdi-check-all</v-icon>
									</v-btn>
								</template>
								<span>Aceptar</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn
										color="error"
										class="ma-1"
										fab
										x-small
										dark
										@click.prevent="rejected(row.id)"
										v-on="on"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Rechazar</span>
							</v-tooltip>
						</template>
						<template v-if="row.state == '02'">
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn
										color="accent"
									
										fab
										x-small
										dark
										@click.prevent="printOrder(row.id)"
										v-on="on"
									>
										<v-icon>mdi-printer</v-icon>
									</v-btn>
								</template>
								<span>Imprimir</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn
										color="warning"
										class="ml-1"
										fab
										x-small
										dark
										@click.prevent="send(row.id)"
										v-on="on"
									>
										<v-icon>mdi-send</v-icon>
									</v-btn>
								</template>
								<span>Enviar</span>
							</v-tooltip>
							<v-tooltip top>
								<template v-slot:activator="{ on }">
									<v-btn
										color="error"
										class="ma-1"
										fab
										x-small
										dark
										@click.prevent="rejected(row.id)"
										v-on="on"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</template>
								<span>Cancelar</span>
							</v-tooltip>
						</template>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn
									color="info"
									fab
									x-small
									dark
									@click.prevent="showDetails(row.id)"
									v-on="on"
								>
									<v-icon>mdi-file-document</v-icon>
								</v-btn>
							</template>
							<span>Ver detalles</span>
						</v-tooltip>
					</td>
				</tr>
			</emb-data-table-without-filter>
		<!-- </div> -->

		<orders-create :showRegisterDialog.sync="showRegisterDialog"> </orders-create>

		<emb-location-dialog
			:showLocationDialog.sync="showLocationDialog"
			:isRouteMenu="true"
			:lat="lat"
			:lng="lng"
			:draggable="false"
			:autoAdd="false"
			:item="null"
		>
		</emb-location-dialog>

		<rejected-form :showRejectedDialog.sync="showRejectedDialog" :recordId="recordId">
		</rejected-form>

		<send-form :showSendDialog.sync="showSendDialog" :recordId="recordId"> </send-form>

		<details-form :showDetailsDialog.sync="showDetailsDialog" :recordId="recordId">
		</details-form>
	</div>
</template>

<script>
import RejectedForm from './Component/Rejected';
import DetailsForm from './Component/Details';
import { deletable } from 'Mixins/deletable';
import SendForm from './Component/Send';
import { confirm } from 'Mixins/confirm';
import OrdersCreate from './Create';

export default {
	data() {
		return {
			showLocationDialog: false,
			showRejectedDialog: false,
			showDetailsDialog: false,
			showRegisterDialog: false,
			showSendDialog: false,
			loadingSubmit: false,
			resource: 'orders',
			showDialog: false,
			recordId: null,
			records: [],
			lat: null,
			lng: null,
		};
	},
	mixins: [deletable, confirm],
	components: {
		RejectedForm,
		DetailsForm,
		OrdersCreate,
		SendForm,
	},
	methods: {
		update() {
			location.reload();
			// this.$eventHub.$emit('reloadDataOrders');
		},
		seeLocation(location) {
			this.lat = location.latitude;
			this.lng = location.longitude;
			this.showLocationDialog = true;
		},
		confirmed(recordId) {
			this.confirm().then(() => {
				this.$http.post(`/${this.resource}/confirmed/${recordId}`).then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						// this.$eventHub.$emit('reloadDataOrders', this.resource);
						location.reload();
					} else {
						this.$message.error(response.data.message);
					}
				});
			});
		},
		showDetails(recordId) {
			this.recordId = recordId;
			this.showDetailsDialog = true;
		},
		rejected(recordId) {
			this.recordId = recordId;
			this.showRejectedDialog = true;
		},
		send(recordId) {
			this.recordId = recordId;
			this.showSendDialog = true;

			// this.confirm().then(() => {
			//     this.$http.post(`/${this.resource}/send/${recordId}`).then(response => {
			//         if (response.data.success) {
			//             this.$message.success(response.data.message);
			//             this.$eventHub.$emit('reloadDataOrders')
			//         } else {
			//             this.$message.error(response.data.message);
			//         }
			//     })
			// })
		},
		deliver(recordId) {
			this.confirm().then(() => {
				this.$http.post(`/${this.resource}/deliver/${recordId}`).then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadDataOrders');
					} else {
						this.$message.error(response.data.message);
					}
				});
			});
		},
		printOrder(recordId) {
			this.$http
				.get(`/${this.resource}/print/${recordId}`)
				.then((response) => {
					if (response.data.success) {
						let zones = response.data.zones;
						// let pc_ip = response.data.pc_ip;
						for (let index = 0; index < zones.length; ++index) {
							let data = JSON.stringify(zones[index]);
							// let url = `https://${pc_ip}/print-api/print/command`;
							let url = `https://print-api.test/print/command`;

							fetch(url, { method: 'POST', body: data })
								.then((dataWrappedByPromise) => dataWrappedByPromise.json())
								.then((data) => {
									if (data.success) {
										this.$message.success(data.message);
									} else {
										this.$message.error(data.message);
									}
								})
								.catch((error) => {
									console.error('Error: ', error);
								});
						}
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					this.$message.error(error.response.data.message);
				});
		},
		// printPreAccount(recordID) {
		// 	this.confirm().then(() => {
		// 		this.$http.get(`/${this.resource}/print/pre-account/${recordID}`).then((response) => {
		// 			if (response.data.success) {
		// 				// let pc_ip = response.data.data.config.pc_ip;
		// 				let data = JSON.stringify(response.data.data);
		// 				// let url = `https://${pc_ip}/print-api/print/pre-account`;
		// 				let url = `https://print-api.test/print/pre-account`;

		// 				fetch(url, { method: 'POST', body: data })
		// 					.then((dataWrappedByPromise) => dataWrappedByPromise.json())
		// 					.then((data) => {
		// 						if (data.success) {
		// 							this.$message.success(data.message);
		// 						} else {
		// 							this.$message.error(data.message);
		// 						}
		// 					})
		// 					.catch((error) => {
		// 						console.error('Error: ', error);
		// 					});
		// 				this.getTablesBySalon(salon_id);
		// 			} else {
		// 				this.$message.error(response.data.message);
		// 			}
		// 		});
		// 	});
		// },
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
