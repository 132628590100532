<template>
	<div>
		<el-dialog
			:visible="showRejectedDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false"
			width="50%"
		>
			<form autocomplete="off" @submit.prevent="submit">
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<div
							class="form-group"
							:class="{ 'error--text': errors.reason_for_rejection }"
						>
							<label>Motivo de la cancelación del pedido</label>
							<el-input v-model="form.reason_for_rejection"></el-input>
							<small
								class="error--text"
								v-if="errors.reason_for_rejection"
								v-text="errors.reason_for_rejection[0]"
							></small>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
						<el-button type="primary" native-type="submit" :loading="loading">
							<template v-if="loading">
								Guardando...
							</template>
							<template v-else>
								Guardar
							</template>
						</el-button>
					</v-col>
				</v-row>
			</form>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showRejectedDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'orders',
			errors: {},
			form: {},
		};
	},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: this.recordId,
				reason_for_rejection: null,
			};
		},
		create() {
			this.initForm();
		},
		submit() {
			this.loading = true;
			this.$http
				// .post(`/${this.resource}/rejected`, this.form)
				.post(`/${this.resource}/cancelled`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadDataOrders');
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.message;
					} else {
						console.log(error);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showRejectedDialog', false);
			this.initForm();
		},
	},
};
</script>
