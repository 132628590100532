<template>
	<div>
		<el-dialog
			title="Detalles del pedido"
			:visible="showDetailsDialog"
			@close="close"
			@open="create"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			width="70%"
		>
			<template v-if="loaded">
				<v-row dense>
					<v-col cols="12" xl="6" lg="12">
						<v-card dark color="secondary">
							<v-card-text>
								<h6>DELIVERY</h6>
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td>
													<v-icon left color="info">mdi-phone</v-icon>
													<strong>Telf.:</strong>
													{{ order.shipping.contact.telephone }}
													<strong>Entregar a:</strong>
													{{ order.shipping.contact.name }}
												</td>
											</tr>
											<tr>
												<td>
													<v-icon left color="info"
														>mdi-arrow-right-bold</v-icon
													>
													<strong>Dirección:</strong>
													{{ order.shipping.location.address }}
												</td>
											</tr>
											<tr>
												<td>
													<v-icon left color="info"
														>mdi-backup-restore</v-icon
													>
													<strong>Referencia:</strong>
													{{ order.shipping.reference }}
													<strong>Interior N°:</strong>
													{{ order.shipping.inside_number }}
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="12" xl="6" lg="12">
						<v-card dark color="secondary">
							<v-card-text>
								<h6>FACTURACIÓN</h6>
								<v-simple-table dense>
									<template v-slot:default>
										<tbody>
											<tr>
												<td>
													<v-icon left color="info"
														>mdi-file-document</v-icon
													>
													<strong>Comprobante:</strong>
													{{ order.document_type }}
												</td>
											</tr>
											<tr>
												<td>
													<v-icon left color="info"
														>mdi-clipboard-outline</v-icon
													>
													<strong>N° Doc.:</strong>
													{{ order.payment.customer_number }}
												</td>
											</tr>
											<tr>
												<td>
													<v-icon left color="info"
														>mdi-file-document-box</v-icon
													>
													<strong>Nombre o Razón Social:</strong>
													{{ order.payment.customer_name }}
												</td>
											</tr>
										</tbody>
									</template>
								</v-simple-table>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="12" xl="12" lg="12" md="12" sm="12">
						<h6 class="text-center">Pedidos</h6>
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
										<th class="text-left">Producto</th>
										<th class="text-right">Cantidad</th>
										<th class="text-right">Precio Unit.</th>
										<th class="text-right">Total</th>
										<th class="text-right">Nota</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(item, index) in order.items" :key="index">
										<td>{{ item.item.name }}</td>
										<td class="text-right align-middle">
											{{ item.quantity }}
										</td>
										<td class="text-right">
											<emb-currency-sign></emb-currency-sign>
											{{ item.unit_price }}
										</td>
										<td class="text-right">
											<emb-currency-sign></emb-currency-sign> {{ item.total }}
										</td>
										<td class="text-right p-0">
											<v-chip v-if="item.note" color="error" small>
												{{ item.note }}
											</v-chip>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr class="h6">
										<td colspan="4" class="text-right"><h5>Total:</h5></td>
										<td class="text-right">
											<h5>S/ {{ order.total }}</h5>
										</td>
									</tr>
								</tfoot>
							</template>
						</v-simple-table>
					</v-col>
				</v-row>
			</template>
		</el-dialog>
	</div>
</template>

<script>
export default {
	props: ['showDetailsDialog', 'recordId'],
	data() {
		return {
			loaded: false,
			resource: 'orders',
			order: {},
		};
	},
	created() {},
	methods: {
		async create() {
			await this.$http.get(`/${this.resource}/details/${this.recordId}`).then((response) => {
				this.order = response.data.data;
				this.loaded = true;
			});
		},
		close() {
			this.$emit('update:showDetailsDialog', false);
			this.loaded = false;
			this.order = {};
		},
	},
};
</script>
