<template>
	<div class="mt-6">
		<v-card color="secondary" v-if="opened_cash && loaded">
			<v-tabs
				v-model="tab"
				background-color="primary"
				icons-and-text
				fixed-tabs
				centered
				dark
			>
				<v-tabs-slider></v-tabs-slider>

				<v-tab href="#tab-1">
					<!-- <v-badge
                        color="accent"
                        dot
                    >
                        SALONES
                    </v-badge> -->
					SALONES
					<v-icon>mdi-table</v-icon>
				</v-tab>

				<v-tab href="#tab-2">
					<!-- <v-badge
                        color="error"
                        content="5"
                    >
                        DELIVERY
                    </v-badge> -->
					DELIVERY
					<v-icon>mdi-bike</v-icon>
				</v-tab>

				<v-tab :to="{ name: 'FastSale' }">
					<!-- <v-badge
                        color="accent"
                        dot
                    >
                        VENTA RÁPIDA
                    </v-badge> -->
					VENTA RÁPIDA
					<v-icon>mdi-clock-fast</v-icon>
				</v-tab>
				<v-tab href="#tab-4">
					COMPROBANTE
					<v-icon>mdi-file-document-box</v-icon>
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item :key="1" :value="'tab-' + 1">
					<emb-command-create></emb-command-create>
				</v-tab-item> 
				<v-tab-item :key="2" :value="'tab-' + 2">
					<emb-orders-index></emb-orders-index>
				</v-tab-item>
				<v-tab-item :key="4" :value="'tab-' + 4">
					<emb-invoice></emb-invoice>
				</v-tab-item>
			</v-tabs-items>
		</v-card>
		<v-row v-if="!opened_cash && loaded">
			<v-col cols="12" xl="12">
				<el-alert
					title="Usted no podrá realizar ventas porque no ha aperturado caja."
					type="error"
					center
					effect="dark"
					:closable="false"
				></el-alert>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import CommandCreate from 'Views/Admin/Commands/Create';
import Invoice from 'Views/Admin/Documents/Invoice';
import OrdersIndex from 'Views/Admin/Orders/Index';

export default {
	data() {
		return {
			tab: null,
			opened_cash: false,
			loaded: false,
		};
	},
	components: {
		embCommandCreate: CommandCreate,
		embOrdersIndex: OrdersIndex,
		embInvoice: Invoice,
	},
	async created() {
		await this.getCashOpened();
	},
	methods: {
		getCashOpened() {
			this.$http.get(`/constants/opened-cash`).then((response) => {
				this.opened_cash = response.data;

				this.loaded = true;
			});
		},
	},
};
</script>
