<template>
	<el-dialog
		:visible="showSendDialog"
		@close="close"
		@open="create"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		:show-close="false"
		width="50%"
	>
		<form autocomplete="off" @submit.prevent="submit">
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<div class="form-group" :class="{ 'error--text': errors.motorised_id }">
						<label>Motorizado</label>
						<el-select v-model="form.motorised_id" dusk="motorised_id">
							<el-option
								v-for="option in motorized"
								:key="option.id"
								:value="option.id"
								:label="option.name"
							></el-option>
						</el-select>
						<small
							class="error--text"
							v-if="errors.motorised_id"
							v-text="errors.motorised_id[0]"
						></small>
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" xl="12" lg="12" md="12" sm="12">
					<el-button type="danger" @click.prevent="close()">Cancelar</el-button>
					<el-button type="primary" native-type="submit" :loading="loading">
						<template v-if="loading">
							Enviando...
						</template>
						<template v-else>
							Enviar
						</template>
					</el-button>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
export default {
	props: ['showSendDialog', 'recordId'],
	data() {
		return {
			loading: false,
			titleDialog: null,
			resource: 'orders',
			motorized: [],
			errors: {},
			form: {},
		};
	},
	methods: {
		initForm() {
			this.errors = {};
			this.form = {
				id: this.recordId,
				motorised_id: null,
			};
		},
		async create() {
			await this.initForm();
			await this.getTables();
		},
		getTables() {
			this.$http.get(`/${this.resource}/tables`).then((response) => {
				this.motorized = response.data;
			});
		},
		submit() {
			this.loading = true;
			this.$http
				.post(`/${this.resource}/send`, this.form)
				.then((response) => {
					if (response.data.success) {
						this.$message.success(response.data.message);
						this.$eventHub.$emit('reloadDataOrders');
						this.close();
					} else {
						this.$message.error(response.data.message);
					}
				})
				.catch((error) => {
					if (error.response.status === 422) {
						this.errors = error.response.data.message;
					} else {
						console.log(error);
					}
				})
				.then(() => {
					this.loading = false;
				});
		},
		close() {
			this.$emit('update:showSendDialog', false);
			this.initForm();
		},
	},
};
</script>
